/* src/components/Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 1rem;
}

.navbar-logo a {
    color: white;
    font-size: 1.5rem;
    text-decoration: none;
    font-weight: bold;
}

.navbar-links {
    list-style: none;
    display: flex;
    gap: 1rem;
}

.navbar-links li {
    margin: 0;
}

.navbar-links a,
.navbar-link-button {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    padding: 10px;
    display: inline-block;
    transition: color 0.3s ease;
}

.navbar-links a:hover:not(:disabled),
.navbar-link-button:hover:not(:disabled) {
    color: #ff9800;
}

.navbar-link-button {
    background: none;
    border: none;
    cursor: pointer;
    font: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 40px;
    /* Ensure a consistent height for the button */
}

.navbar-link-button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.fixed-width-button {
    width: 80px;
    /* Set a fixed width for the button */
}