table {
    margin: 10px auto 10px 10px;
    table-layout: auto;
    width: max-content;

    border-radius: 10px;
    overflow-x: auto;
    border-collapse: collapse;
}

  
th, td {
    padding: 12px;
    border-bottom: 1px solid var(--border-color);
    text-align: left;
}

th {
    background-color: var(--table-header-bg);
    color: var(--text-color);
    text-transform: uppercase;
}

tr {
    background-color: var(--table-row-bg);
}

tr:hover {
    background-color: var(--table-row-hover-bg);
}

td {
    color: var(--text-color);
}

table, th, td {
    border: 1px solid var(--border-color);
}