/* src/components/UserList.css */

/* Define dark mode variables */
:root {
    --background-color: #121212;
    --text-color: #ffffff;
    --table-header-bg: #1f1f1f;
    --table-row-bg: #2c2c2c;
    --table-row-hover-bg: #3c3c3c;
    --border-color: #444;
  }
  
  /* Apply styles to body for dark mode */
  body {
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  